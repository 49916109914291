import React, { useCallback, useState } from 'react';
import { useHistory, useParams, Redirect } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { gql, useQuery } from '@apollo/client';
import { QuizStateConsumer, Quiz, Question } from '@customer-frontend/quiz';
import { useAuth } from '@customer-frontend/auth';
import {
  getQuizApplicationFragment,
  useQuizApplication,
} from '@customer-frontend/services';
import { useEventService } from '@customer-frontend/events';
import {
  LoadingSpinner,
  Button,
  ButtonProps,
  useNotification,
} from '@eucalyptusvc/design-system';
import { useUrlQuery } from '@customer-frontend/utils';
import { getConfig } from '@customer-frontend/config';
import {
  CustomerAttributes,
  ProblemType,
  QuizApplication,
  SubmitApplicationMutation,
  QuizPageQueryVariables,
} from '@customer-frontend/graphql-types';
import { StartQuiz, StartQuizProps } from './start';
import { ContinueQuiz, ContinueQuizProps } from './continue';
import { QuestionNextButtonLabel } from './question';
import { SkipQuizButton } from './skip-quiz-button';
import {
  AnswerApplicationQuestionAnswersResponse,
  calculateFirstUnansweredQuestionId,
  getPrimaryButtonPalette,
  isWithin4Weeks,
} from './helpers';
import { Logger } from '@customer-frontend/logger';
import { useShowZendeskWidget } from '@customer-frontend/consultation';
import { useFeatureFlagClient } from '@customer-frontend/feature-flags';

export const QuizPage = ({
  profileRoute,
  onQuizSubmitted,
  NextButtonComponent = Button,
  StartQuizComponent = StartQuiz,
  ContinueQuizComponent = ContinueQuiz,
  reviewImageInstructions,
  startQuizButtonLabel = 'Next',
  continueQuizButtonLabel = 'Resume',
  nextButtonLabel = 'Next',
  logger,
  brandColors,
}: {
  profileRoute: string;
  onQuizSubmitted?: (
    quizApplication: SubmitApplicationMutation['submitApplication'],
  ) => Promise<void>;
  NextButtonComponent?: React.FC<ButtonProps>;
  StartQuizComponent?: (props: StartQuizProps) => React.ReactElement;
  ContinueQuizComponent?: (props: ContinueQuizProps) => React.ReactElement;
  reviewImageInstructions?: Partial<Record<ProblemType, string[]>>;
  startQuizButtonLabel?: string;
  continueQuizButtonLabel?: string;
  nextButtonLabel?: QuestionNextButtonLabel;
  logger: Logger;
  brandColors?: { cardBgColor: string };
}): React.ReactElement => {
  const [started, setStarted] = useState(false);
  const [isForwards, setForwards] = useState(true);
  const { quizApplicationId, questionId } = useParams<{
    quizApplicationId: string;
    questionId?: string;
  }>();
  const history = useHistory();
  const event = useEventService();
  const urlQuery = useUrlQuery();
  const config = getConfig();
  const { formatMessage } = useIntl();
  const notifications = useNotification();
  const featureFlagClient = useFeatureFlagClient();
  const quizSpeedOptimisationEnabled =
    featureFlagClient.getMultivariate('XP_QUIZ_SPEED_OPTIMISATION') ===
    'variation';
  const { loggedInUser } = useAuth();
  const { data: quizApplicationData } = useQuizApplication(
    quizApplicationId,
    quizSpeedOptimisationEnabled,
  );
  const resp = useQuery<
    {
      getQuizApplication: QuizApplication & {
        answers: AnswerApplicationQuestionAnswersResponse[];
      };
      customerAttributes: CustomerAttributes;
    },
    QuizPageQueryVariables
  >(
    gql`
      query QuizPage($id: String!) {
        customerAttributes {
          id
          conditions
          medications
          allergies
        }
        getQuizApplication(id: $id) {
          ...QuizApplication
        }
      }
      ${getQuizApplicationFragment}
    `,
    {
      variables: { id: quizApplicationId },
      skip: !quizSpeedOptimisationEnabled,
      onCompleted: ({ getQuizApplication: application }) => {
        let firstUnansweredQuestionId: string | undefined;
        try {
          firstUnansweredQuestionId = calculateFirstUnansweredQuestionId(
            application.quiz,
            application.answers,
          );
        } catch (error) {
          notifications.error({
            message: formatMessage(
              {
                defaultMessage:
                  'Error loading quiz. Please reach out to {email}',
                description:
                  'Error message when quiz fails to load with an email to use for support',
              },
              { email: config.supportEmail },
            ),
            duration: 10000,
          });
          logger.error('Error finding first unanswered question in quiz.', {
            quizId: application.quiz.id,
            quizApplicationId: application.id,
            error,
          });
          return history.push(profileRoute);
        }

        const appStartedWithin4Weeks = isWithin4Weeks(application.createdAt);

        if (firstUnansweredQuestionId && appStartedWithin4Weeks && !isRestart) {
          goToQuestion(firstUnansweredQuestionId);
          return;
        }

        const firstQuestionId = application.quiz?.questionsOrder?.[0];
        goToQuestion(firstQuestionId);
        return;
      },
    },
  );
  const quizApp = quizSpeedOptimisationEnabled
    ? resp.data?.getQuizApplication
    : quizApplicationData?.getQuizApplication;
  const problemType = quizApp?.problemType;
  const isRestart = urlQuery.get('restart') === 'true';
  const consultation = quizApp?.consultation;
  const consultationId = consultation?.id || '';

  useShowZendeskWidget({
    problemType: quizApp?.problemType,
    disabled: quizApp?.purpose !== 'INITIAL',
  });

  const quizCompleteRoute = `/quiz/${quizApplicationId}/complete${window.location.search}`;

  const handleQuizSubmitted = useCallback(
    async (
      submittedQuizApplication: SubmitApplicationMutation['submitApplication'],
    ): Promise<void> => {
      if (consultationId && problemType && loggedInUser?.id) {
        event.consultation.surveyCompleted({
          consultationId: consultationId,
          problemType: problemType,
          userId: loggedInUser?.id,
        });
      }

      onQuizSubmitted?.(submittedQuizApplication);

      return history.push(quizCompleteRoute);
    },
    [
      history,
      event,
      loggedInUser?.id,
      problemType,
      consultationId,
      onQuizSubmitted,
      quizCompleteRoute,
    ],
  );

  const handleQuizStarted = useCallback(
    (activeQuestionIndex: number | undefined): void => {
      if (!quizApp) {
        return;
      }

      setStarted(true);
      setForwards(true);

      if (!activeQuestionIndex && consultationId && loggedInUser?.id) {
        // Consultation Created will not be sent for tracker & decoupled quizes
        // as there is no consultation (and therefore no consultation ID)
        event.consultation.created({
          consultationId: consultationId as string,
          problemType: problemType as string,
          userId: loggedInUser?.id as string,
        });
      }

      event.quiz.start({
        quizApplicationId,
        deprecated_consultation_id: consultationId,
        problemType: problemType as string,
        userId: loggedInUser?.id,
        status: consultation?.status,
        label: problemType,
        medium: consultation?.medium,
        action_source: 'website',
        quizDefault: quizApp.quiz.default,
        quizCode: quizApp.quiz.quizCode,
      });
    },
    [
      consultation?.medium,
      consultation?.status,
      consultationId,
      event.consultation,
      event.quiz,
      loggedInUser,
      problemType,
      quizApplicationId,
      quizApp,
    ],
  );

  const goToQuestion = (questionId: string): void => {
    return history.push(
      `/quiz/${quizApplicationId}/question/${questionId}${window.location.search}`,
    );
  };

  const restartQuiz = (): void => {
    const firstQuestionId = quizApp?.quiz?.questionsOrder?.[0];
    if (!firstQuestionId) {
      return;
    }
    goToQuestion(firstQuestionId);
  };

  const displayQuestion = started || quizApp?.purpose === 'TRACKER';

  if (quizApp?.submittedAt) {
    return <Redirect to={quizCompleteRoute} />;
  }

  return (
    <div>
      {quizApp?.id ? (
        <Quiz
          quizApplicationId={quizApp.id}
          quizApplicationData={resp.data?.getQuizApplication}
          customerAttributesData={resp.data?.customerAttributes}
          onQuizSubmitted={handleQuizSubmitted}
          goToQuestion={goToQuestion}
          activeQuestionId={questionId}
          isRestart={isRestart}
          started={!!displayQuestion}
          profileRoute={profileRoute}
          logger={logger}
          quizSpeedOptimisationEnabled={quizSpeedOptimisationEnabled}
        >
          {displayQuestion ? (
            <Question
              NextButtonComponent={NextButtonComponent}
              reviewImageInstructions={reviewImageInstructions}
              nextButtonLabel={nextButtonLabel}
              isForwards={isForwards}
              setForwards={setForwards}
              onRestart={restartQuiz}
              problemType={quizApp?.problemType}
              goToQuestion={goToQuestion}
              brandColors={brandColors}
            />
          ) : (
            <QuizStateConsumer>
              {({ activeQuestionIndex, loading }): React.ReactElement => {
                if (loading) {
                  return (
                    <div className="flex justify-center p-5">
                      <LoadingSpinner />
                    </div>
                  );
                }

                return (
                  <div className="text-center">
                    {!activeQuestionIndex ? (
                      <StartQuizComponent
                        isReview={consultation?.stage === 'REVIEW'}
                        isFollowUp={consultation?.stage === 'FOLLOW_UP'}
                        problemType={problemType}
                        purpose={quizApp?.purpose}
                      />
                    ) : (
                      <ContinueQuizComponent
                        isReview={consultation?.stage === 'REVIEW'}
                        isFollowUp={consultation?.stage === 'FOLLOW_UP'}
                        problemType={problemType}
                        purpose={quizApp?.purpose}
                      />
                    )}
                    <div className="mt-8 space-y-4">
                      <NextButtonComponent
                        level="primary"
                        palette={getPrimaryButtonPalette(config.brand)}
                        isFullWidth
                        onClick={(): void =>
                          handleQuizStarted(activeQuestionIndex)
                        }
                      >
                        {!activeQuestionIndex
                          ? startQuizButtonLabel
                          : continueQuizButtonLabel}
                      </NextButtonComponent>
                      <SkipQuizButton quizQuestions={quizApp.quiz.questions} />
                    </div>
                  </div>
                );
              }}
            </QuizStateConsumer>
          )}
        </Quiz>
      ) : (
        <div className="flex justify-center">
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
};
