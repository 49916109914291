import { ESegmentDestination, ISegmentEventDestination } from './types';

export const SEGMENT_DESTINATION_WITH_ONLY_REDSHIFT = {
  [ESegmentDestination.ALL]: false,
  [ESegmentDestination.REDSHIFT_PILOT]: true,
  [ESegmentDestination.REDSHIFT_JUNIPER]: true,
  [ESegmentDestination.REDSHIFT_JUNIPER_UK]: true,
};

export const SEGMENT_DESTINATION_WITH_ONLY_REDSHIFT_AMPLITUDE_AND_KLAVIYO = {
  [ESegmentDestination.ALL]: false,
  [ESegmentDestination.REDSHIFT_PILOT]: true,
  [ESegmentDestination.REDSHIFT_JUNIPER]: true,
  [ESegmentDestination.REDSHIFT_JUNIPER_UK]: true,
  [ESegmentDestination.AMPLITUDE]: true,
  [ESegmentDestination.KLAVIYO]: true,
};

export const DEFAULT_SEGMENT_EVENT_DESTINATION: ISegmentEventDestination = {
  [ESegmentDestination.ALL]: false,
  [ESegmentDestination.FACEBOOK_PIXEL]: true,
  [ESegmentDestination.FULLSTORY]: true,
  [ESegmentDestination.GOOGLE_ADS]: true,
  [ESegmentDestination.GOOGLE_ANALYTICS]: true,
  [ESegmentDestination.TIKTOK_CONVERSIONS]: true,
  [ESegmentDestination.KLAVIYO]: false,
  [ESegmentDestination.HOTJAR]: false,
  [ESegmentDestination.CUSTOMERIO]: true,
  [ESegmentDestination.PINTEREST]: true,
  [ESegmentDestination.GOOGLE_CLOUD_PUB_SUB]: true,
};

export const SEGMENT_DESTINATION_WITH_KLAVIYO = {
  ...DEFAULT_SEGMENT_EVENT_DESTINATION,
  [ESegmentDestination.KLAVIYO]: true,
};
