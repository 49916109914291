import { gql, useMutation, useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import {
  CustomerAttributes,
  MutationAnswerApplicationQuestionsArgs,
  QuizConsultationQueryVariables,
  QuizApplication,
  SubmitApplicationMutation,
  SubmitApplicationMutationVariables,
  AnswerApplicationQuestionMutation,
  AnswerApplicationQuestionMutationVariables,
} from '@customer-frontend/graphql-types';
import {
  getQuizApplicationFragment,
  quizApplicationAnswerFragment,
  useBackButtonBehaviour,
} from '@customer-frontend/services';
import { notificationService } from '@customer-frontend/notifications';
import React, { FC, useCallback } from 'react';
import {
  AnswerApplicationQuestionAnswersResponse,
  calculateFirstUnansweredQuestionId,
  calculateNextQuestionId,
  calculatePreviousQuestionId,
  getPrefillForShortcode,
  isWithin4Weeks,
} from './helpers';
import { NextParams, QuizStateProvider } from './state';
import { useProgress } from '@customer-frontend/navbar';
import { useNotification } from '@eucalyptusvc/design-system';
import { getConfig } from '@customer-frontend/config';
import { useHistory } from 'react-router-dom';
import { Logger } from '@customer-frontend/logger';
import { useEventService } from '@customer-frontend/events';

interface QuizProps {
  children: React.ReactNode;
  quizApplicationId: string;
  quizApplicationData?: QuizApplication & {
    answers: AnswerApplicationQuestionAnswersResponse[];
  };
  customerAttributesData?: CustomerAttributes;
  activeQuestionId?: string;
  isRestart: boolean;
  started: boolean;
  onQuizSubmitted: (
    application?: SubmitApplicationMutation['submitApplication'],
  ) => Promise<void>;
  goToQuestion: (questionId: string) => void;
  profileRoute: string;
  logger: Logger;
  quizSpeedOptimisationEnabled?: boolean;
}

export const Quiz: FC<QuizProps> = ({
  goToQuestion,
  onQuizSubmitted: onQuizSubmittedProp,
  customerAttributesData,
  quizApplicationId,
  quizApplicationData,
  activeQuestionId,
  isRestart,
  started,
  children,
  profileRoute,
  logger,
  quizSpeedOptimisationEnabled,
}: QuizProps) => {
  const notifications = useNotification();
  const config = getConfig();
  const history = useHistory();
  const { formatMessage } = useIntl();
  const eventService = useEventService();

  const resp = useQuery<
    {
      getQuizApplication: QuizApplication & {
        answers: AnswerApplicationQuestionAnswersResponse[];
      };
      customerAttributes: CustomerAttributes;
    },
    QuizConsultationQueryVariables
  >(
    gql`
      query QuizConsultation($id: String!) {
        customerAttributes {
          id
          conditions
          medications
          allergies
        }
        getQuizApplication(id: $id) {
          ...QuizApplication
        }
      }
      ${getQuizApplicationFragment}
    `,
    {
      variables: { id: quizApplicationId },
      skip: quizSpeedOptimisationEnabled,
      onCompleted: ({ getQuizApplication: application }) => {
        let firstUnansweredQuestionId: string | undefined;
        try {
          firstUnansweredQuestionId = calculateFirstUnansweredQuestionId(
            application.quiz,
            application.answers,
          );
        } catch (error) {
          notifications.error({
            message: formatMessage(
              {
                defaultMessage:
                  'Error loading quiz. Please reach out to {email}',
                description:
                  'Error message when quiz fails to load with an email to use for support',
              },
              { email: config.supportEmail },
            ),
            duration: 10000,
          });
          logger.error('Error finding first unanswered question in quiz.', {
            quizId: application.quiz.id,
            quizApplicationId: application.id,
            error,
          });
          return history.push(profileRoute);
        }

        const appStartedWithin4Weeks = isWithin4Weeks(application.createdAt);

        if (firstUnansweredQuestionId && appStartedWithin4Weeks && !isRestart) {
          goToQuestion(firstUnansweredQuestionId);
          return;
        }

        const firstQuestionId = application.quiz?.questionsOrder?.[0];
        goToQuestion(firstQuestionId);
        return;
      },
    },
  );

  const [answerQuestionMutation] = useMutation<
    AnswerApplicationQuestionMutation,
    AnswerApplicationQuestionMutationVariables
  >(
    gql`
      mutation AnswerApplicationQuestion(
        $applicationId: String!
        $questionId: String!
        $answer: String
        $answersArray: [String!]
        $answerId: String
        $optionIds: [String!]
      ) {
        answerApplicationQuestion(
          applicationId: $applicationId
          questionId: $questionId
          answer: $answer
          answersArray: $answersArray
          answerId: $answerId
          optionIds: $optionIds
        ) {
          ...QuizApplicationAnswer
        }
      }
      ${quizApplicationAnswerFragment}
    `,
  );

  const [submitApplication] = useMutation<
    SubmitApplicationMutation,
    SubmitApplicationMutationVariables
  >(
    gql`
      mutation SubmitApplication($id: String!) {
        submitApplication(id: $id) {
          id
          ...QuizApplicationAnswer
        }
      }
      ${quizApplicationAnswerFragment}
    `,
  );

  const application = quizApplicationData ?? resp.data?.getQuizApplication;

  const quiz = application?.quiz;

  const activeQuestion = quiz?.questions?.find(
    (question) => question.id === activeQuestionId,
  );
  const activeQuestionIndex =
    quiz?.questionsOrder?.findIndex((q) => q === activeQuestionId) ?? 0;
  const lastQuestionIndex = quiz?.questionsOrder?.length || -1;

  const activeAnswer = application?.answers?.find(
    (answer) => answer?.question?.id === activeQuestionId,
  );

  const customerAttributes =
    customerAttributesData ?? resp.data?.customerAttributes;

  const backButtonBehaviour = React.useMemo(() => {
    if (quiz && activeQuestionId) {
      const previousQuestionId = calculatePreviousQuestionId(
        quiz,
        application?.answers,
        activeQuestionId,
      );
      if (previousQuestionId) {
        return (): void => goToQuestion(previousQuestionId);
      }
    }
    return null;
  }, [quiz, activeQuestionId, application?.answers, goToQuestion]);

  useBackButtonBehaviour(backButtonBehaviour);
  useProgress(
    started ? activeQuestionIndex : -1,
    started ? lastQuestionIndex : -1,
  );

  const onQuizSubmitted = useCallback(
    (
      submittedApplication?: SubmitApplicationMutation['submitApplication'],
    ): Promise<void> => {
      if (application && submittedApplication) {
        eventService.quiz.complete({
          quizApplicationId: submittedApplication.id,
          quizPurpose: application.purpose,
          deprecated_consultation_id: submittedApplication.consultation?.id,
          problemType: submittedApplication.problemType,
          status: submittedApplication?.consultation?.status,
          quizDefault: application.quiz.default,
          quizCode: application.quiz.quizCode,
        });
      }
      return onQuizSubmittedProp(submittedApplication);
    },
    [onQuizSubmittedProp, eventService, application],
  );

  const next = useCallback(
    async ({ answersArray, answer, question }: NextParams): Promise<void> => {
      if (!(quizApplicationId && activeQuestionId && quiz)) {
        logger.error(
          `Cannot continue quiz, missing quiz information. quizApplicationId: ${quizApplicationId}, activeQuestionId: ${activeQuestionId}, question: ${question.id}`,
        );
        return notificationService.show({
          type: 'error',
          message: formatMessage({
            defaultMessage: 'Unable to continue quiz',
            description:
              'Error message shown when the quiz cannot move to the next question',
          }),
        });
      }

      const isRadioOrCheckbox = ['RADIO', 'CHECKBOX'].includes(question.type);

      if (quizSpeedOptimisationEnabled) {
        try {
          const quizQuestion = application.quiz.questions.find(
            (q) => q.id === question.id,
          );

          if (!quizQuestion) {
            throw new Error(
              `Failed to find quiz question with id ${question.id}`,
            );
          }

          const questionOptions = quizQuestion.questionOptions;
          let optimisticAnswers = application.answers;

          // Answers can come in 1 of 3 forms, and each need to be handled differently to convert ids to their string based answer used in `calculateNextQuestionId`
          // 1) `answersArray` - An array of answer ids, eg ['ce9a6295-6f43-4bfe-b6e7-2dd2f35cd16f', '77198fdf-4d1c-4705-9323-4d65ff0313b1']
          // 2) `answer` - An answer id, eg '80f612d2-3249-4266-805c-1f5cc4084da2'
          // 3) `answer` - A free text answer string, eg '120' or 'Please contact my GP for more information'

          const answerStrings: string[] = [];
          let answerString = '';

          if (answersArray) {
            for (const answerId of answersArray) {
              const matchedAnswer = questionOptions?.find(
                (qo) => qo.id === answerId,
              );
              if (matchedAnswer?.option) {
                answerStrings.push(matchedAnswer.option);
              }
            }
          } else {
            // This block handles both `answer` cases.
            // First treat the `answer` as an id and try to match it to a question. If no match is found, fall back to using the `answer` string
            const matchedAnswer = questionOptions?.find(
              (qo) => qo.id === answer,
            );
            answerString = matchedAnswer?.option ?? answer ?? '';
          }

          const optimisticAnswer = {
            id: `${activeQuestionId}-optimistic-answer`,
            answer: answerString,
            answersArray: answerStrings,
            englishAnswer: answerString,
            englishAnswersArray: answerStrings,
            applicationId: quizApplicationId,
            updatedAt: new Date().toISOString(),
            createdAt: new Date().toISOString(),
            application,
            question: {
              id: quizQuestion.id,
              shortcode: quizQuestion.shortcode,
              questionOptions: quizQuestion.questionOptions,
              required: quizQuestion.required,
              type: quizQuestion.type,
              createdAt: quizQuestion.createdAt,
              updatedAt: quizQuestion.updatedAt,
            },
          };

          // If this question has already been answered previously, replace the optimistic answer rather than appending to the array
          const answeredQuestionIndex = optimisticAnswers.findIndex(
            (answer) => answer.question?.id === activeQuestionId,
          );
          if (answeredQuestionIndex > -1) {
            const optimisticAnswersCopy = [...optimisticAnswers];
            optimisticAnswersCopy.splice(
              answeredQuestionIndex,
              1,
              optimisticAnswer,
            );
            optimisticAnswers = optimisticAnswersCopy;
          } else {
            optimisticAnswers = [...optimisticAnswers, optimisticAnswer];
          }

          answerQuestionMutation({
            variables: {
              applicationId: quizApplicationId,
              questionId: activeQuestionId,
              answerId: activeAnswer?.id ?? null,
              ...(isRadioOrCheckbox
                ? {
                    optionIds: answersArray?.length
                      ? answersArray
                      : [answer ?? ''],
                  }
                : {
                    answer,
                    answersArray: answersArray,
                  }),
            },
            optimisticResponse: {
              answerApplicationQuestion: {
                id: application.id,
                answers: [...optimisticAnswers],
                customerId: application.customerId,
                problemType: application.problemType,
              },
            },
            onError: () => {
              return goToQuestion(activeQuestionId);
            },
          });

          if (application.submittedAt) {
            return onQuizSubmitted({
              ...application,
              answers: [...application.answers],
            });
          }

          const nextQuestionId = calculateNextQuestionId(
            quiz,
            optimisticAnswers,
            activeQuestionId,
          );

          if (nextQuestionId) {
            return goToQuestion(nextQuestionId);
          }

          const { data: submittedApplication } = await submitApplication({
            variables: { id: quizApplicationId },
          });

          return onQuizSubmitted(submittedApplication?.submitApplication);
        } catch (err) {
          return err;
        }
      } else {
        try {
          const { data: answerData } = await answerQuestionMutation({
            variables: {
              applicationId: quizApplicationId ?? '',
              questionId: activeQuestionId,
              answerId: activeAnswer?.id ?? null,
              ...(isRadioOrCheckbox
                ? {
                    optionIds: answersArray?.length
                      ? answersArray
                      : [answer ?? ''],
                  }
                : {
                    answer,
                    answersArray: answersArray,
                  }),
            },
          });

          // Check if the quiz has been submitted early
          if (answerData?.answerApplicationQuestion?.submittedAt) {
            return onQuizSubmitted(answerData.answerApplicationQuestion); // This is referencing the quiz application
          }

          const nextQuestionId = calculateNextQuestionId(
            quiz,
            answerData?.answerApplicationQuestion?.answers,
            activeQuestionId,
          );

          if (nextQuestionId) {
            return goToQuestion(nextQuestionId);
          }

          const { data: application } = await submitApplication({
            variables: { id: quizApplicationId },
          });

          return onQuizSubmitted(application?.submitApplication);
        } catch (err) {
          if (process.env.NODE_ENV !== 'production') {
            // eslint-disable-next-line no-console
            console.warn(err);
          }

          return err;
        }
      }
    },
    [
      quizApplicationId,
      activeQuestionId,
      quiz,
      logger,
      formatMessage,
      answerQuestionMutation,
      activeAnswer?.id,
      submitApplication,
      onQuizSubmitted,
      goToQuestion,
      quizSpeedOptimisationEnabled,
      application,
    ],
  );

  const [answerQuestionsMutation] = useMutation<
    { answerApplicationQuestions: QuizApplication },
    MutationAnswerApplicationQuestionsArgs
  >(
    gql`
      mutation AnswerApplicationQuestions(
        $applicationId: String!
        $answers: [AnswerInput]
      ) {
        answerApplicationQuestions(
          applicationId: $applicationId
          answers: $answers
        ) {
          ...QuizApplicationAnswer
        }
      }
      ${quizApplicationAnswerFragment}
    `,
  );

  const completeQuiz = useCallback(
    async ({
      answers,
    }: Omit<
      MutationAnswerApplicationQuestionsArgs,
      'applicationId'
    >): Promise<void> => {
      const resp = await answerQuestionsMutation({
        variables: {
          applicationId: quizApplicationId,
          answers: answers ? answers : [],
        },
      });

      if (resp.data) {
        const { data: application } = await submitApplication({
          variables: { id: quizApplicationId },
        });

        return onQuizSubmitted(application?.submitApplication);
      }
    },
    [
      submitApplication,
      onQuizSubmitted,
      answerQuestionsMutation,
      quizApplicationId,
    ],
  );

  return (
    <QuizStateProvider
      value={{
        next,
        question: activeQuestion,
        answer: activeAnswer,
        activeQuestionIndex,
        loading: resp.loading,
        prefill: getPrefillForShortcode(
          customerAttributes,
          activeQuestion?.shortcode,
        ),
        completeQuiz,
        quizApplication: application,
      }}
    >
      {children}
    </QuizStateProvider>
  );
};
